import { useEffect, useState } from "react";
import NavigationBar from "../../navigation/navbar";
import useWindowDimensions from "../../tools/dimensions";
import { Button } from "antd";
import calendar from "../../assets/images/calendar.png";

import Footer from "../../navigation/footer";
//import { Routes, Route, useParams } from 'react-router-dom';
import blogs from "../../datas/blogs"

const BlogArticleComponent = ({ screenWidth }) => {
  const [details, setdetails] = useState(null);
  //const  {title}  = useParams();

  useEffect(() => {
    //setdetails(JSON.parse(localStorage.getItem("details")));
    //const blog = blogs.find(obj => obj.title === title);
    //const searchParams = new URLSearchParams(window.location.search);
    //const title = searchParams.get('title');
    //const blog = blogs.find(obj => obj.title === title.replaceAll("-", " "));
    let currentUrl = window.location.href;
    let parts = currentUrl.split("/");
    const title = parts[parts.length - 1];
    const decodedTitle = decodeURIComponent(title.replaceAll("-", " "))
    const blog = blogs.find(obj => obj.title.toLowerCase() === decodedTitle.toLowerCase());
    //console.log(title)
    setdetails(blog)
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 50,
        }}
      >
        {details && (
          <div style={{ width: "770px" }}>
            <h1 className=" font-weight-normal display-1">
              {details.title}
            </h1>
            {/* <h1
              style={{
                fontSize: "48px",
                lineHeight: "59.33px",
                fontWeight: 600,
              }}
            >
              {details.title}
            </h1> */}
          </div>
        )}
      </div>

      {details && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              width: "191px",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={calendar}
              style={{ width: "50px", height: "50px" }}
              alt=""
            />
            <div
              style={{
                width: "130px",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <p style={{ width: "34px", height: "5px", color: "#2B2928" }}>
                Date
              </p>
              <p style={{ width: "130px", height: "5px", color: "#EF5A24" }}>
                {details.date}
              </p>
            </div>
          </div>
        </div>
      )}
      {details && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={details.image}
            style={{ width: "768px", borderRadius: 10 }}
            alt=""
          />
        </div>
      )}
      {details && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          <div
            style={{
              // width: "768px",
              //   height: "430px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "750px", textAlign: "left" }}>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "29.16px",
                  color: "#656565",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: `${details.description}` }}
                />
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
            // width: "1167px",
            width: "80%",
            // height: "381px",
            backgroundColor: "#FEF7F4",

            borderRadius: 20,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <span
              style={{ width: 100, color: "rgb(239, 90, 36)", fontSize: 20 }}
            >
              Join Gaya
            </span>
            <h1 style={{ fontSize: 36, margin: 40, fontWeight: 600 }}>
              {
                "Speed up your insure quoting process 10x with Gaya. Focus on understanding and meeting your clients’ needs."
              }
            </h1>
            <button
              //type="button"
              //className="ant-btn css-dev-only-do-not-override-1qhpsh8"
              style={{
                backgroundColor: "rgb(239, 90, 36)",
                borderColor: "rgb(239, 90, 36)",
                borderWidth: 0,
                borderRadius: 100,
                width: 169,
                height: 52,
              }}

              onClick={() => {
                //window.location = "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                window.open(
                  "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01",
                  "_blank"
                );
              }}
            >
              <span style={{ color: "white" }}>GET STARTED</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlogArticleComponentMobile = ({ screenWidth }) => {
  const [details, setdetails] = useState(null);
  useEffect(() => {
    let currentUrl = window.location.href;
    let parts = currentUrl.split("/");
    const title = parts[parts.length - 1];
    const decodedTitle = decodeURIComponent(title.replaceAll("-", " "))
    const blog = blogs.find(obj => obj.title.toLowerCase() === decodedTitle.toLowerCase());
    console.log(title)
    setdetails(blog)
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 50,
        }}
      >
        {details && (
          <div style={{}}>
            <h1
              style={{
                fontSize: "30px",
                lineHeight: "37.08px",
                fontWeight: 600,
              }}
            >
              {details.title}
            </h1>
          </div>
        )}
      </div>

      {details && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          <img src={details.image} style={{ width: "90%", borderRadius: 10 }} />
        </div>
      )}

      {details && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          <div
            style={{
              width: "90%",
              //   height: "2190px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "668px", textAlign: "left" }}>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "29.16px",
                  color: "#656565",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: `${details.description}` }}
                />
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -30,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "335px",
          }}
        ></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
            width: "90%",
            // width: "335px",
            // height: "435px",
            backgroundColor: "#FEF7F4",
            justifyContent: "center",
            borderRadius: "20px",

          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",

              // width: "335px",
              // height: "275px",
            }}
          >
            <span
              style={{
                width: "100px",
                // height: "25px",
                color: "#EF5A24",
                fontSize: "20px",
                marginTop: "80px",
              }}
            >
              Join Gaya
            </span>
            <h1
              style={{
                // width: "335px",
                // height: "152px",
                fontSize: "28px",
                lineHeight: "38.25px",
                padding: "30px",
                fontWeight: 600,
              }}
            >
              {
                "Speed up your insure quoting process 10x with Gaya. Focus on understanding and meeting your clients’ needs."
              }
            </h1>
            <Button
              onClick={() => {
                window.open(
                  "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                );
              }}
              type="primary"
              style={{
                marginTop: 10,
                backgroundColor: "#EF5A24",
                borderRadius: 100,
                width: "169px",
                height: "52px",
                marginBottom: "80px",
              }}
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function BlogArticle() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);

  return (
    <div style={{}}>
      <NavigationBar isMobile={isMobile} selected={"Blog"} />
      {isMobile ? (
        <BlogArticleComponentMobile screenWidth={screenWidth} />
      ) : (
        <BlogArticleComponent screenWidth={screenWidth} />
      )}

      <div style={{ marginBottom: 100 }}></div>
      <Footer />
    </div>

  );
}
