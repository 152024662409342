import { useEffect, useState } from "react";
import NavigationBar from "../navigation/navbar";
import useWindowDimensions from "../tools/dimensions";

import Footer from "../navigation/footer";

import blogs from "../datas/blogs";
const Component = ({ screenWidth }) => {
  return (
    <div>
      <div
        style={{
          marginTop: "50px",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ height: "79px" }}>
          {/* <h1
            style={{
              // fontSize: "64px",
              color: "#1E1F22",
              // fontFamily: "Manrope, sans-serif",
            }}
            className="display-1 "
          >
            Gaya’s Blog
          </h1> */}

          <h1 className=" font-weight-normal display-1 px-5">
          Gaya’s Blog
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
            minHeight: "500px",
          }}
        >
          <div
            style={{
              width: "95%",
              marginTop: 50,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {blogs.map((m, i) => (
              <div
                onClick={() => {
                  //localStorage.setItem("details", JSON.stringify(m));
                  window.location.href = "/blog/"+m.title.replaceAll(" ", "-").toLowerCase();
                }}
                style={{
                  width: "30%",
                  // height: "329px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <img
                  src={m.image}
                  style={{
                    height: "206px",
                    objectFit: "cover",
                    borderRadius: 10,
                  }}
                  alt={"Alt"}
                />
                <div style={{}}>
                  <p
                    style={{
                      fontSize: "18px",
                      lineHeight: "24.66px",
                      color: "#2B2928",
                    }}
                  >
                    {m.title}
                  </p>
                </div>
                <div style={{ width: "89px", height: "20px" }}>
                  <p
                    style={{
                      fontSize: "15px",
                      lineHeight: "24.66px",
                      color: "#EF5A24",
                    }}
                  >
                    <div>Read more...</div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ComponentMobile = ({ screenWidth }) => {
  return (
    <div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "335px", height: "79px" }}>
          <h1
            style={{
              color: "#1E1F22",
              // fontSize: "36px",

              // fontFamily: "Manrope, sans-serif",
            }}
            className="display-1"
          >
            Gaya’s Blog
          </h1>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {blogs.map((m, i) => (
          <div
            onClick={() => {
              //localStorage.setItem("details", JSON.stringify(m));
              window.location.href = "/blog/"+m.title.replaceAll(" ", "-").toLowerCase();
            }}
            style={{
              width: "95%",
              // height: "329px",
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <img
              src={m.image}
              style={{
                height: "230px",
                objectFit: "cover",
                borderRadius: 10,
              }}
              alt={"Alt"}
            />
            <div style={{}}>
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "24.66px",
                  color: "#2B2928",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                {m.title}
              </p>
            </div>
            <div style={{ width: "89px", height: "20px", marginBottom: 30 }}>
              <p
                style={{
                  fontSize: "15px",
                  lineHeight: "24.66px",
                  color: "#EF5A24",
                }}
              >
                <div>Read more...</div>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function Blog() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);

  return (
    <div style={{}}>
      <NavigationBar isMobile={isMobile} selected={"Blog"} />

      {isMobile ? <ComponentMobile screenWidth={screenWidth} /> : <Component />}

      <Footer />
    </div>
  );
}
