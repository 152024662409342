
import { useEffect, useState } from 'react';
import Page1 from './pages/index.js';
import Page2 from './pages/developers.js.js';
import Page3 from './pages/careers.js';
import CareersDetail from './pages/careers/careers_detail.js';
import Blog from './pages/blog.js';
import BlogArticle from './pages/blog/blog_article.js';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import FulfillmentPolicy from './navigation/fulfillment.js';


function App() {
  
  return (
    <div className="App" style={{ fontFamily: "Manrope, sans-serif", }}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Page1 />} />
            <Route path="developers" element={<Page2 />} />
            <Route path="careers" element={<Page3 />} />
            <Route path="careers/*" element={<CareersDetail />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/*" element={<BlogArticle />} />
            <Route path="*" element={<Page1 />} />
            <Route path="policy/fulfillment-policy" element={<FulfillmentPolicy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}




export default App;
